import React from 'react';
import { Box, Container, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Button, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CodeIcon from '@mui/icons-material/Code';
import StorageIcon from '@mui/icons-material/Storage';
import ApiIcon from '@mui/icons-material/Api';
import WebIcon from '@mui/icons-material/Web';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import CloudIcon from '@mui/icons-material/Cloud';

const CustomSoftware = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" gutterBottom align="center">
            Custom Software Development
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Transform Your Business with Custom Software
            </Typography>
            <Typography paragraph>
              At Zenith Times, we specialize in developing tailored software solutions that address your specific business challenges and drive growth. Our experienced team of developers creates robust, scalable, and user-friendly applications that help you streamline operations, enhance productivity, and gain a competitive edge.
            </Typography>
            <Typography paragraph>
              Whether you need a web application, desktop software, or a comprehensive enterprise solution, we have the expertise to deliver high-quality software that meets your requirements and exceeds your expectations.
            </Typography>
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 2 }}
            >
              Get a Free Consultation
            </Button>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Our Development Approach
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Requirements Analysis" 
                  secondary="We begin by thoroughly understanding your business needs and objectives to ensure our solution aligns perfectly with your goals."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Agile Development" 
                  secondary="We follow an agile methodology, delivering incremental value and allowing for adjustments as your needs evolve."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Quality Assurance" 
                  secondary="Rigorous testing ensures your software is reliable, secure, and performs optimally under all conditions."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Ongoing Support" 
                  secondary="We provide continuous maintenance and support to ensure your software remains up-to-date and performs flawlessly."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
            Our Development Services
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <WebIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Web Application Development
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Responsive, intuitive web applications built with modern frameworks and technologies to provide seamless user experiences.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <DesktopWindowsIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Desktop Application Development
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Powerful desktop applications that enhance productivity and streamline business processes across your organization.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <ApiIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              API Development
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Robust, secure APIs that enable seamless integration between different systems and services.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <StorageIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Database Design & Development
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Efficient database solutions that ensure optimal performance, data integrity, and security for your applications.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CloudIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Cloud Solutions
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Scalable cloud-based applications that leverage the power of cloud computing for enhanced performance and accessibility.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CodeIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Full-Stack Development
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Comprehensive solutions that cover both frontend and backend development, ensuring seamless integration and functionality.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 4, mt: 4, bgcolor: 'primary.main', color: 'white' }}>
            <Typography variant="h5" gutterBottom align="center">
              Technologies We Work With
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">C#</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">PHP</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">Python</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">JavaScript</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">React</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">Node.js</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">MS SQL Server</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">MySQL</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">MongoDB</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">Redis</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">Elastic Search</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Typography variant="body1" align="center">Docker</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomSoftware; 