import React from 'react';
import { Container, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Button, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import BuildIcon from '@mui/icons-material/Build';
import AssessmentIcon from '@mui/icons-material/Assessment';

const Modernization = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" gutterBottom align="center">
            Application Modernization
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Transform Legacy Systems for the Digital Age
            </Typography>
            <Typography paragraph>
              At Zenith Times, we help organizations modernize their legacy applications to meet the demands of today's digital landscape. Our modernization services transform outdated systems into agile, scalable, and efficient solutions that drive business growth and innovation.
            </Typography>
            <Typography paragraph>
              Whether you need to migrate to the cloud, update your technology stack, or completely rebuild your application, our team of experts will guide you through the modernization process with minimal disruption to your business operations.
            </Typography>
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 2 }}
            >
              Discuss Your Modernization Needs
            </Button>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Our Modernization Approach
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Assessment & Planning" 
                  secondary="We begin with a thorough assessment of your current systems, identifying pain points, and developing a comprehensive modernization strategy."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Incremental Implementation" 
                  secondary="We implement modernization in phases to minimize disruption and ensure a smooth transition for your users and business processes."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Continuous Testing" 
                  secondary="We maintain rigorous testing throughout the modernization process to ensure functionality, performance, and security."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Knowledge Transfer" 
                  secondary="We provide comprehensive documentation and training to ensure your team can effectively maintain and extend the modernized system."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
            Our Modernization Services
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <UpdateIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Legacy System Modernization
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Transforming outdated systems into modern, efficient applications using current technologies and best practices.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CloudQueueIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Cloud Migration
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Migrating on-premise applications to cloud platforms like AWS, Azure, or Google Cloud for improved scalability and cost efficiency.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SpeedIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Performance Optimization
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Enhancing application performance through code optimization, database tuning, and infrastructure improvements.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SecurityIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Security Enhancement
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Upgrading security measures to protect against modern threats and ensure compliance with current regulations.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <BuildIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              UI/UX Redesign
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Modernizing user interfaces and experiences to improve usability, accessibility, and overall user satisfaction.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <AssessmentIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Architecture Redesign
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Restructuring application architecture to improve maintainability, scalability, and integration capabilities.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 4, mt: 4, bgcolor: 'primary.main', color: 'white' }}>
            <Typography variant="h5" gutterBottom align="center">
              Technologies We Modernize To
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">React</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Node.js</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Python</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Java</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">.NET Core</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">AWS</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Azure</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Google Cloud</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Modernization; 