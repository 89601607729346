import React from 'react';
import { Box, Container, Typography, Grid, Paper, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import CodeIcon from '@mui/icons-material/Code';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import SecurityIcon from '@mui/icons-material/Security';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import UpdateIcon from '@mui/icons-material/Update';
import AppsIcon from '@mui/icons-material/Apps';

const About = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" gutterBottom align="center">
            About Zenith Times
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Our Story
            </Typography>
            <Typography paragraph>
              Zenith Times is a technology consulting and software development company dedicated to helping businesses achieve their digital transformation goals. Founded with a vision to bridge the gap between complex technology and business needs, we provide comprehensive software solutions that drive growth and innovation.
            </Typography>
            <Typography paragraph>
              Our team of experienced professionals brings together expertise in software development, architecture design, and technology modernization. We understand that each business has unique challenges and requirements, which is why we offer tailored solutions that address your specific needs.
            </Typography>
            <Typography>
              At Zenith Times, we believe in building long-term partnerships with our clients. We don't just deliver projects; we become an extension of your team, working collaboratively to ensure your success in the digital landscape.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Our Mission
            </Typography>
            <Typography paragraph>
              Our mission is to empower businesses with innovative software solutions and expert technical guidance. We strive to be your trusted technology partner, helping you navigate the complexities of the digital world and achieve sustainable growth.
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Our Values
            </Typography>
            <Typography component="div">
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <BusinessIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography>Client-Centric Approach</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CodeIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography>Technical Excellence</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SecurityIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography>Security & Reliability</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <UpdateIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Typography>Continuous Innovation</Typography>
              </Box>
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
            Our Expertise
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CodeIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Custom Software Development
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Full-stack development solutions using C#, PHP, and Python with modern frameworks and best practices.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <ArchitectureIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Software Architecture
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Expert guidance in designing scalable and maintainable software architectures for your business needs.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SecurityIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Code Review
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Comprehensive code analysis for standards compliance, performance optimization, and security enhancement.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CloudQueueIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              DevSecOps
            </Typography>
            <Typography variant="body2" color="text.secondary">
              End-to-end DevOps solutions with security integration using Azure DevOps and SonarQube.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <UpdateIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Technology Modernization
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Transform legacy applications into modern, efficient solutions using cutting-edge technologies.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <AppsIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Our Solutions
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Innovative products including our domain management mobile application and more.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About; 