import React from 'react';
import { Box, Container, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Button, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import StorageIcon from '@mui/icons-material/Storage';
import ApiIcon from '@mui/icons-material/Api';

const Architecture = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" gutterBottom align="center">
            Software Architecture Support
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Design Your Software for Success
            </Typography>
            <Typography paragraph>
              At Zenith Times, we understand that a well-designed software architecture is the foundation of any successful application. Our experienced architects work closely with you to design scalable, maintainable, and efficient software solutions that align with your business goals and technical requirements.
            </Typography>
            <Typography paragraph>
              Whether you're starting a new project or need to improve an existing system, our architecture consulting services will help you make informed decisions about technology choices, system design, and implementation strategies.
            </Typography>
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 2 }}
            >
              Schedule a Consultation
            </Button>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Our Architecture Approach
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Business Analysis" 
                  secondary="We begin by understanding your business requirements, goals, and constraints to ensure our architecture supports your strategic objectives."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Technology Assessment" 
                  secondary="We evaluate and recommend the most appropriate technologies and frameworks based on your specific needs and industry best practices."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Architecture Design" 
                  secondary="We create detailed architecture designs that define system components, their interactions, and the overall structure of your software solution."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Implementation Guidance" 
                  secondary="We provide guidance throughout the implementation process to ensure the architecture is correctly implemented and maintained."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
            Our Architecture Services
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <ArchitectureIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Architecture Design
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Comprehensive architecture designs that define the structure, components, and interactions of your software system.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CloudQueueIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Cloud Architecture
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Design of scalable, resilient cloud-based architectures leveraging AWS, Azure, or Google Cloud platforms.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SecurityIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Security Architecture
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Design of secure architectures that protect your data and systems from threats while ensuring compliance with security standards.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SpeedIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Performance Optimization
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Architecture designs that optimize performance, scalability, and resource utilization for high-traffic applications.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <StorageIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Data Architecture
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Design of efficient data architectures that ensure optimal data storage, retrieval, and management.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <ApiIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Microservices Architecture
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Design of modular, scalable microservices architectures that enable independent deployment and scaling of services.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 4, mt: 4, bgcolor: 'primary.main', color: 'white' }}>
            <Typography variant="h5" gutterBottom align="center">
              Architecture Patterns We Implement
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Microservices</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Event-Driven</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Layered Architecture</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">CQRS</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Serverless</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Domain-Driven Design</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Event Sourcing</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Hexagonal Architecture</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Architecture; 