import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import SecurityIcon from '@mui/icons-material/Security';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import UpdateIcon from '@mui/icons-material/Update';
import AppsIcon from '@mui/icons-material/Apps';

const services = [
  {
    title: 'Custom Software Development',
    description: 'Full-stack development solutions using C#, PHP, and Python with modern frameworks and best practices.',
    icon: <CodeIcon sx={{ fontSize: 40 }} />,
    path: '/services/custom-software',
  },
  {
    title: 'Software Architecture',
    description: 'Expert guidance in designing scalable and maintainable software architectures for your business needs.',
    icon: <ArchitectureIcon sx={{ fontSize: 40 }} />,
    path: '/services/architecture',
  },
  {
    title: 'Code Review',
    description: 'Comprehensive code analysis for standards compliance, performance optimization, and security enhancement.',
    icon: <SecurityIcon sx={{ fontSize: 40 }} />,
    path: '/services/code-review',
  },
  {
    title: 'DevSecOps',
    description: 'End-to-end DevOps solutions with security integration using Azure DevOps and SonarQube.',
    icon: <CloudQueueIcon sx={{ fontSize: 40 }} />,
    path: '/services/devsecops',
  },
  {
    title: 'Technology Modernization',
    description: 'Transform legacy applications into modern, efficient solutions using cutting-edge technologies.',
    icon: <UpdateIcon sx={{ fontSize: 40 }} />,
    path: '/services/modernization',
  },
  {
    title: 'Our Solutions',
    description: 'Innovative products including our domain management mobile application and more.',
    icon: <AppsIcon sx={{ fontSize: 40 }} />,
    path: '/#',
  },
];

const Home = () => {
  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          py: 8,
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" component="h1" gutterBottom>
                Welcome to Zenith Times
              </Typography>
              <Typography variant="h5" paragraph>
                Your trusted partner in software development and technology solutions
              </Typography>
              <Button
                component={RouterLink}
                to="/contact"
                variant="contained"
                color="secondary"
                size="large"
                sx={{ mt: 2 }}
              >
                Get Started
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Services Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h3" component="h2" align="center" gutterBottom>
          Our Services
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" paragraph>
          Comprehensive software solutions tailored to your business needs
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {services.map((service) => (
            <Grid item key={service.title} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    {service.icon}
                  </Box>
                  <Typography gutterBottom variant="h5" component="h3" align="center">
                    {service.title}
                  </Typography>
                  <Typography align="center" color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                  <Button
                    component={RouterLink}
                    to={service.path}
                    size="small"
                    color="primary"
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Home; 