import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

// Layout Components
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import CustomSoftware from './pages/services/CustomSoftware';
import Architecture from './pages/services/Architecture';
import CodeReview from './pages/services/CodeReview';
import DevSecOps from './pages/services/DevSecOps';
import Modernization from './pages/services/Modernization';
import Solutions from './pages/services/Solutions';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/custom-software" element={<CustomSoftware />} />
          <Route path="/services/architecture" element={<Architecture />} />
          <Route path="/services/code-review" element={<CodeReview />} />
          <Route path="/services/devsecops" element={<DevSecOps />} />
          <Route path="/services/modernization" element={<Modernization />} />
          <Route path="/services/solutions" element={<Solutions />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App; 