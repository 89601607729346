import React from 'react';
import { Container, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Button, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import BuildIcon from '@mui/icons-material/Build';
import SpeedIcon from '@mui/icons-material/Speed';
import CodeIcon from '@mui/icons-material/Code';
import AssessmentIcon from '@mui/icons-material/Assessment';

const DevSecOps = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" gutterBottom align="center">
            DevSecOps Services
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Integrate Security into Your Development Process
            </Typography>
            <Typography paragraph>
              At Zenith Times, we help organizations implement DevSecOps practices to seamlessly integrate security into the software development lifecycle. Our approach ensures that security is not an afterthought but a fundamental part of your development process from the beginning.
            </Typography>
            <Typography paragraph>
              By combining development, security, and operations, we help you build more secure, reliable, and efficient software while reducing time-to-market and operational costs.
            </Typography>
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 2 }}
            >
              Learn More About Our DevSecOps Services
            </Button>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Our DevSecOps Approach
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Security by Design" 
                  secondary="We integrate security considerations into every stage of the development process, from planning to deployment."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Automated Security Testing" 
                  secondary="We implement automated security testing tools and processes to identify vulnerabilities early in the development cycle."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Continuous Integration/Continuous Deployment (CI/CD)" 
                  secondary="We set up robust CI/CD pipelines that include security checks and automated testing at every stage."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Infrastructure as Code (IaC)" 
                  secondary="We use IaC to ensure consistent, secure, and repeatable infrastructure deployments."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
            Our DevSecOps Services
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SecurityIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Security Integration
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Integrating security tools and practices into your development and deployment pipelines.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CloudQueueIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Cloud Security
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Securing cloud infrastructure and applications with best practices for AWS, Azure, and Google Cloud.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <BuildIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              CI/CD Implementation
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Setting up secure and efficient continuous integration and continuous deployment pipelines.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SpeedIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Automation
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Automating security testing, compliance checks, and deployment processes to improve efficiency and reduce human error.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CodeIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Infrastructure as Code
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Implementing Infrastructure as Code practices to ensure consistent, secure, and repeatable infrastructure deployments.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <AssessmentIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Compliance Automation
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Automating compliance checks and reporting to ensure your systems meet regulatory requirements.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 4, mt: 4, bgcolor: 'primary.main', color: 'white' }}>
            <Typography variant="h5" gutterBottom align="center">
              DevSecOps Tools We Implement
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Jenkins</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">GitLab CI</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">GitHub Actions</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Terraform</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Docker</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Kubernetes</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">SonarQube</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">OWASP ZAP</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DevSecOps; 