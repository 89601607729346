import React from 'react';
import { Container, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Button, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BusinessIcon from '@mui/icons-material/Business';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import SecurityIcon from '@mui/icons-material/Security';
import BuildIcon from '@mui/icons-material/Build';

const Solutions = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1" gutterBottom align="center">
            Enterprise Solutions
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Tailored Solutions for Your Business Challenges
            </Typography>
            <Typography paragraph>
              At Zenith Times, we understand that every business faces unique challenges and opportunities. Our enterprise solutions are designed to address your specific needs, helping you streamline operations, improve efficiency, and drive growth.
            </Typography>
            <Typography paragraph>
              Whether you need a custom CRM system, an integrated ERP solution, or a specialized industry application, our team of experts will work closely with you to develop a solution that perfectly aligns with your business objectives.
            </Typography>
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 2 }}
            >
              Discuss Your Business Needs
            </Button>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Our Solution Development Process
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Business Analysis" 
                  secondary="We begin by thoroughly understanding your business processes, challenges, and objectives to ensure our solution addresses your specific needs."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Solution Design" 
                  secondary="We design a tailored solution that integrates seamlessly with your existing systems and workflows, focusing on usability and efficiency."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Agile Development" 
                  secondary="We develop your solution using agile methodologies, allowing for continuous feedback and adjustments throughout the development process."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Implementation & Support" 
                  secondary="We handle the implementation of your solution and provide ongoing support to ensure it continues to meet your evolving business needs."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
            Our Enterprise Solutions
          </Typography>
          <Divider sx={{ mb: 4 }} />
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <LightbulbIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Custom CRM Systems
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Tailored customer relationship management systems designed to streamline your sales, marketing, and customer service processes.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <BusinessIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              ERP Solutions
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Integrated enterprise resource planning systems that connect your business processes and provide real-time insights for better decision-making.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <AnalyticsIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Business Intelligence
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Advanced analytics and reporting solutions that transform your data into actionable insights to drive business growth.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <CloudQueueIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Cloud Solutions
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Scalable cloud-based applications and services that provide flexibility, cost-efficiency, and improved collaboration.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SecurityIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Compliance Solutions
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Specialized applications to help you meet regulatory requirements and maintain compliance with industry standards.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <BuildIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Industry-Specific Solutions
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Custom applications tailored to the unique needs and challenges of your specific industry.
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 4, mt: 4, bgcolor: 'primary.main', color: 'white' }}>
            <Typography variant="h5" gutterBottom align="center">
              Industries We Serve
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Healthcare</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Finance</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Manufacturing</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Retail</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Education</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Government</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Technology</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="body1" align="center">Professional Services</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Solutions; 